export interface User {
  id: string;
  name: string;
  phoneNumber: string;
  devices: Device[];
}

export interface Device {
  id: string;
  productType: ProductType;
  productLabel: string;
  whenUseStarted: number;
  whenUseEnded: number;
}

export enum ProductType {
  LOTION = 'LOTION',
  PILL = 'PILL',
  SHAMPOO = 'SHAMPOO',
}

export interface Dose {
  deviceId: string;
  index: number;
  whenWasTaken: number;
  quantity: number;
  whenWasUploaded: number;
}

export interface DateInterval {
  start: Date;
  end: Date;
}
