import { useState } from 'react';
import { ArrowDownIcon, LotionIcon, PillIcon, ShampooIcon } from '../icons';
import { Device, ProductType } from '../models';
import css from './device-selector.module.css';

interface Props {
  selectedDevice: Device;
  deviceList: Device[];
  onDeviceSelected: (device: Device) => void;
}

const DeviceSelector = ({ selectedDevice, deviceList, onDeviceSelected }: Props) => {

  const [showOptionsToSelect, setShowOptionsToSelect] = useState(false);

  const selectDevice = (device: Device) => {
    setShowOptionsToSelect(false);
    onDeviceSelected(device);
  };

  return (
    <div className={css.Selector}>

      <div className={css.Product} onClick={() => setShowOptionsToSelect(!showOptionsToSelect)}>
        <DeviceIcon device={selectedDevice} className={css.IconSelected} />
        <span className={css.ProductLabel}>
          {selectedDevice.productLabel}
        </span>
        <ArrowDownIcon className={css.Icon}
          style={deviceList.length > 1 ? { transform: showOptionsToSelect ? 'rotate(180deg)' : 'rotate(0)' } : { opacity: 0 }} />
      </div>

      {showOptionsToSelect ?
        deviceList.filter(device => device !== selectedDevice)
          .map(device =>
            <div key={device.id}
              onClick={() => selectDevice(device)}
              className={css.ProductOption}>
              <DeviceIcon device={device} className={css.Icon} />
              <span className={css.ProductLabel}>
                {device.productLabel}
              </span>
            </div>
          )
        : null}

    </div>
  );
};

const DeviceIcon = (props: { device: Device, className: string }) => {
  switch (props.device.productType) {
  case ProductType.LOTION:
    return <LotionIcon className={props.className} />;
  case ProductType.PILL:
    return <PillIcon className={props.className} />;
  case ProductType.SHAMPOO:
    return <ShampooIcon className={props.className} />;
  default:
    return <></>;
  }
};

export default DeviceSelector;
