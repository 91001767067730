import { Device, Dose, ProductType } from '../models';
import css from './dose-list.module.css';

const DoseList = ({ doses, device }: { doses: Dose[]; device: Device; }) => {

  return (
    <ul className={css.DoseListPage}>
      {doses.sort((a, b) => a.whenWasTaken > b.whenWasTaken ? 1 : -1)
        .map((dose) => (
          <DoseItem key={dose.whenWasTaken} dose={dose} device={device} />
        ))}
    </ul>
  );
};

const DoseItem = ({ dose, device }: { dose: Dose; device: Device; }) => {
  const date = new Date(dose.whenWasTaken);
  return (
    <li className={css.DoseItem}>
      <span> {date.toLocaleDateString('pt')} </span>
      <span> 🕒 {date.toLocaleTimeString('pt').substr(0, 5)} </span>
      <DoseQuantity dose={dose} device={device} />
    </li>
  );
};

const DoseQuantity = ({ dose, device }: { dose: Dose; device: Device; }) => {
  switch (device.productType) {
  case ProductType.LOTION:
    return <span> ~ {(dose.quantity * 0.15).toFixed(2)} ml 💧 </span>;
  case ProductType.SHAMPOO:
    return <span> ~ {(dose.quantity * 0.5).toFixed(2)} ml 💧 </span>;
  default:
    return <></>;
  }
};

export default DoseList;
