import { FunctionComponent, HTMLAttributes, useState } from 'react';

interface Props extends HTMLAttributes<HTMLDivElement> {
  timeout: number;
  onLongPress: () => any;
}

const LongPressArea: FunctionComponent<Props> =
  ({ timeout, onLongPress, children, ...divProps }) => {

    const [pressEventId, setPressEventId] = useState<number | undefined>();

    const setupPressEvent = () => {
      if (pressEventId) { return; }
      const eventId: number = setTimeout(() => onLongPress(), timeout) as any;
      setPressEventId(eventId);
    };

    const cancelPressEvent = () => {
      clearTimeout(pressEventId);
      setPressEventId(undefined);
    };

    return (
      <div
        onMouseDown={() => setupPressEvent()}
        onMouseUp={() => cancelPressEvent()}

        onTouchStart={() => setupPressEvent()}
        onTouchEnd={() => cancelPressEvent()}
        onTouchMove={() => cancelPressEvent()}

        onContextMenu={(event) => {
          event.preventDefault();
          event.stopPropagation();
          return false;
        }}

        {...divProps}>

        {children}

      </div>);
  };

export default LongPressArea;
