import { PersonIcon } from '../icons';
import { User } from '../models';
import css from './user-preview.module.css';

const UserPreview = ({ user }: { user: User }) => (

  <div className={css.User}>
    <PersonIcon className={css.UserIcon} />
    <div className={css.UserInfo}>
      <span className={css.UserName}>{user.name}</span>
      {/* <span className={css.UserId}>{user.id}</span> */}
    </div>
  </div>

);

export default UserPreview;
