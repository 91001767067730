import { endOfDay, endOfToday, startOfDay } from 'date-fns';
import { pt } from 'date-fns/locale';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CalendarIcon } from '../icons';
import { DateInterval } from '../models';
import css from './date-interval-selector.module.css';

registerLocale('pt-BR', pt);

interface Props {
  interval: DateInterval;
  onChange: (interval: DateInterval) => void;
}

const DateIntervalSelector = ({ interval, onChange }: Props) => (

  <div className={css.Selector}>
    <CalendarIcon className={css.Icon} />
    <div className={css.InputsContainer}>
      <DatePicker
        locale='pt-BR'
        dateFormat='dd/MM/yyyy'
        selected={interval.start}
        maxDate={interval.end}
        customInput={<ReadonlyInput />}
        onChange={date => onChange({
          start: startOfDay(date as Date),
          end: interval.end
        })} />
      <hr></hr>
      <DatePicker
        locale='pt-BR'
        dateFormat='dd/MM/yyyy'
        selected={interval.end}
        minDate={interval.start}
        maxDate={endOfToday()}
        customInput={<ReadonlyInput />}
        onChange={date => onChange({
          start: interval.start,
          end: endOfDay(date as Date)
        })} />
    </div>
  </div>

);

const ReadonlyInput = ({ value, onClick }: any) => (
  <button onClick={onClick} className={css.Input}>
    {value}
  </button>
);

export default DateIntervalSelector;
