import { addMonths, endOfDay, endOfMonth, endOfToday, isWithinInterval, min, startOfDay, startOfMonth, startOfToday } from 'date-fns';
import { useEffect, useState } from 'react';
import { DateInterval, Device, Dose, User } from '../models';
import DateIntervalSelector from './date-interval-selector';
import DeviceSelector from './device-selector';
import DoseList from './dose-list';
import DosesCalendar from './doses-calendar';
import LongPressArea from './long-press-area';
import Stats from './stats';
import UserPreview from './user-preview';
import css from './user-stats.module.css';

const UserStats = ({ user, doses, users }: { user: User, doses: Dose[], users: User[] }) => {

  const [selectedDevice, setSelectedDevice] = useState<Device>(user.devices[0]);
  const [interval, setInterval] = useState<DateInterval>({
    start: startOfMonth(startOfToday()), end: endOfToday()
  });
  const [selectedDoses, setSelectedDoses] = useState<Dose[]>([]);
  const [allowCustomInterval, setAllowCustomInterval] = useState(false);

  useEffect(() => {
    setSelectedDoses(doses.filter(dose =>
      dose.deviceId === selectedDevice.id &&
      // users.map(user => user.devices[2].id).find(id => id === dose.deviceId) &&
      isWithinInterval(dose.whenWasTaken, interval)
    ));
  }, [doses, selectedDevice, interval, users]);

  useEffect(() => {
    if (allowCustomInterval === false) {
      setInterval({ start: startOfMonth(startOfToday()), end: endOfToday() });
    }
  }, [allowCustomInterval]);

  const safeAddMonthsToInterval = (numberOfMonths: number) =>
    setInterval({
      start: min([
        startOfDay(startOfMonth(addMonths(interval.start, numberOfMonths))),
        startOfMonth(startOfToday())
      ]),
      end: min([
        endOfDay(endOfMonth(addMonths(interval.end, numberOfMonths))),
        endOfToday()
      ])
    });

  const getDosesPerDevice = (device: Device): Dose[] => {
    return doses.filter(dose =>
      dose.deviceId === device.id &&
      isWithinInterval(dose.whenWasTaken, interval) &&
      dose.whenWasTaken > 1624114800000
    );
  };

  const defaultView = (
    <div className={css.UserStats}>
      <LongPressArea
        timeout={1000}
        onLongPress={() => setAllowCustomInterval(!allowCustomInterval)}>
        <UserPreview user={user} />
      </LongPressArea>
      <div className={css.Selectors}>
        <DeviceSelector
          selectedDevice={selectedDevice}
          deviceList={user.devices}
          onDeviceSelected={(device) => setSelectedDevice(device)} />
        {allowCustomInterval ?
          <DateIntervalSelector
            interval={interval}
            onChange={(interval) => setInterval(interval)} />
          : null
        }
      </div>
      <DosesCalendar
        doses={selectedDoses}
        interval={interval}
        showNavigationArrows={allowCustomInterval === false}
        onNextMonth={() => safeAddMonthsToInterval(1)}
        onPrevMonth={() => safeAddMonthsToInterval(-1)}
      />
      <Stats
        device={selectedDevice}
        doses={selectedDoses}
        interval={interval} />
      <DoseList doses={selectedDoses} device={selectedDevice} />
    </div>
  );

  const alternateView = (
    <div className={css.UserStatsAlternate}>
      <div className={css.UserStatsAlternateUserName}>
        <UserPreview user={user} />
      </div>
      <div className={css.Grid}>
        {user.devices.map(device =>
          <div key={device.id} className={css.GridItem}>
            <div className={css.Selectors}>
              <DeviceSelector
                selectedDevice={device}
                deviceList={[device]}
                onDeviceSelected={() => console.log('...')} />
              {allowCustomInterval ?
                <DateIntervalSelector
                  interval={interval}
                  onChange={(interval) => setInterval(interval)} />
                : null
              }
            </div>
            <DosesCalendar
              doses={getDosesPerDevice(device)}
              interval={interval}
              showNavigationArrows={allowCustomInterval === false}
              onNextMonth={() => safeAddMonthsToInterval(1)}
              onPrevMonth={() => safeAddMonthsToInterval(-1)}
            />
            <Stats
              device={device}
              doses={getDosesPerDevice(device)}
              interval={interval} />
            <DoseList doses={getDosesPerDevice(device)} device={device} />
          </div>
        )}
      </div>
    </div>
  );

  return user.id === '000' ? alternateView : defaultView;
};

export default UserStats;
