import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

firebase.initializeApp({
  apiKey: 'AIzaSyDbug2ME2DfSeTER-7IoogtuEVSNIJtFoc',
  authDomain: 'pilfil-capfil.firebaseapp.com',
  projectId: 'pilfil-capfil',
  storageBucket: 'pilfil-capfil.appspot.com',
  messagingSenderId: '10252217447',
  appId: '1:10252217447:web:13493dd854649c8e85f8c2',
  measurementId: 'G-RG5VHNQLBL'
});

export const auth = firebase.auth();
export const firestore = firebase.firestore();
