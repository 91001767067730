import { useEffect, useState } from 'react';
import css from './app.module.css';
import Loading from './components/loading';
import Login from './components/login';
import UserList from './components/user-list';
import UserStats from './components/user-stats';
import { Dose, User } from './models';
import { auth, firestore } from './services/firebase';

const App = () => {

  const [authUser, setAuthUser] = useState<typeof auth.currentUser | undefined>(undefined);
  const [users, setUsers] = useState<User[]>();
  const [selectedUser, setSelectedUser] = useState<User>();
  const [doses, setDoses] = useState<Dose[]>([]);

  useEffect(() => auth.onAuthStateChanged(authUser => setAuthUser(authUser)), []);

  useEffect(() => {
    if (authUser) {
      return firestore.collection('users').onSnapshot(usersCollection => {
        setUsers(usersCollection.docs.map(doc => ({ ...doc.data(), id: doc.id }) as User));
        setUsers(users => users? [users[0], users[1], users[2], users[9],users[6], users[4], users[8], users[5], users[7], users[10], users[3]]:undefined);
        // setUsers(users => users ? [users[0], users[1], users[2], users[9], users[6], users[4]] : undefined);
      });
    } else {
      setSelectedUser(undefined);
    }
  }, [authUser]);

  useEffect(() => {
    if (authUser) {
      return firestore.collection('doses').onSnapshot(dosesCollection => {
        setDoses(dosesCollection.docs.map(doc => {
          const dose = doc.data() as Dose;
          dose.whenWasTaken = Math.min(dose.whenWasTaken, dose.whenWasUploaded);
          return dose;
        }).filter(dose => dose.whenWasTaken > Date.parse('2021-04-22')));
      });
    }
  }, [authUser]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [selectedUser]);

  return (
    <div>
      <header className={css.Header}>
        <span> CAPFIL </span>
        {selectedUser ? (
          <button onClick={() => setSelectedUser(undefined)} className={css.BackButton}>
            Voltar
          </button>) : null}
        {authUser ? (
          <button onClick={() => auth.signOut()} className={css.SignOutButton}>
            Sair
          </button>) : null}
      </header>
      { authUser === undefined ?
        <Loading />
        : authUser === null ?
          <Login />
          : selectedUser === undefined ?
            <UserList users={users} onUserSelected={((user) => setSelectedUser(user))} />
            :
            <UserStats user={selectedUser} doses={doses} users={users || []} />
      }
    </div>
  );
};

export default App;
