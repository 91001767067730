import { User } from '../models';
import Loading from './loading';
import css from './user-list.module.css';
import UserPreview from './user-preview';

interface Props {
  users: User[] | undefined;
  onUserSelected: (user: User) => void;
}

const UserList = ({ users, onUserSelected }: Props) => {

  return users ? (
    <ul className={css.UserList}>
      {users.map(user =>
        <li key={user.id} onClick={() => onUserSelected(user)}>
          <a href='###'>
            <UserPreview user={user} />
          </a>
        </li>)}
    </ul>)
    :
    <Loading />;
};

export default UserList;
