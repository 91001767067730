import { differenceInDays } from 'date-fns';
import { CheckIcon, CloseIcon } from '../icons';
import { DateInterval, Device, Dose, ProductType } from '../models';
import css from './stats.module.css';

interface Props {
  device: Device;
  doses: Dose[];
  interval: DateInterval;
}

const Stats = ({ device, doses, interval }: Props) => {

  const totalDoseQuantity = () => {
    switch (device.productType) {
    case ProductType.PILL:
      return doses.length;
    case ProductType.LOTION:
      return doses.map(dose => dose.quantity)
        .reduce((total, quantity) => total + quantity * 0.15, 0);
    case ProductType.SHAMPOO:
      return doses.map(dose => dose.quantity)
        .reduce((total, quantity) => total + quantity * 0.5, 0);
    }
  };

  const averageDoseQuantity = () => {
    if (!doses.length) { return 0; }
    return (totalDoseQuantity() / doses.length);
  };

  const totalNumberOfDays = () =>
    differenceInDays(interval.end, interval.start) + 1;

  const numberOfDaysUsing = () => {
    const uniqueDays = new Set<number>();
    doses.forEach(dose =>
      uniqueDays.add((new Date(dose.whenWasTaken)).setHours(0, 0, 0, 0)));
    return uniqueDays.size;
  };

  const numberOfDaysNotUsing = () => {
    return totalNumberOfDays() - numberOfDaysUsing();
  };

  const adherence = () => {
    const percentage = numberOfDaysUsing() / totalNumberOfDays();
    return (percentage * 100).toFixed(1);
  };

  return (
    <div className={css.Stats}>
      <div>
        <label> Quantidade total </label>
        {device.productType === ProductType.PILL ?
          <span> {totalDoseQuantity()} unidades 💊 </span> :
          <span> {totalDoseQuantity().toFixed(2)} ml 💧</span>}
      </div>
      {device.productType !== ProductType.PILL ?
        <div>
          <label> Média por aplicação </label>
          <span> {averageDoseQuantity().toFixed(2)} ml 💧 </span>
        </div> : null}
      <div>
        <label> Aderência </label>
        <span> {adherence()} % </span>
      </div>
      <div>
        <label> Frequência </label>
        <span>
          <div className={css.Frequency}>
            <CheckIcon className={css.CheckIcon} />
            {numberOfDaysUsing()} dia(s)
          </div>
          <div className={css.Frequency}>
            <CloseIcon className={css.CloseIcon} />
            {numberOfDaysNotUsing()} dia(s)
          </div>
        </span>
      </div>
    </div>
  );
};

export default Stats;
