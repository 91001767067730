import { eachMonthOfInterval, format, isSameDay, isWithinInterval } from 'date-fns';
import { pt } from 'date-fns/locale';
import Calendar from 'react-calendar';
import { ArrowDownIcon } from '../icons';
import { DateInterval, Dose } from '../models';
import css from './doses-calendar.module.css';

interface Props {
  doses: Dose[];
  interval: DateInterval;
  showNavigationArrows?: boolean;
  onNextMonth?: () => void;
  onPrevMonth?: () => void;
}

const DosesCalendar = ({ doses, interval, showNavigationArrows, onNextMonth, onPrevMonth }: Props) => {

  const calendarTitle = (month: Date) =>
    format(month, 'MMMM - yyyy', { locale: pt });

  return (
    <div className={css.DosesCalendar}>
      {eachMonthOfInterval(interval).map(month =>
        <div key={month.getTime()}>
          <div className={css.CalendarHeader}>
            {showNavigationArrows ? <ArrowDownIcon onClick={onPrevMonth} className={css.Icon} style={{ transform: 'rotate(90deg)' }} /> : null}
            <span className={css.CalendarTitle}> {calendarTitle(month)} </span>
            {showNavigationArrows ? <ArrowDownIcon onClick={onNextMonth} className={css.Icon} style={{ transform: 'rotate(-90deg)' }} /> : null}
          </div>
          <Calendar
            locale={'pt-BR'}
            className={css.Calendar}
            tileClassName={({ date }) =>
              doses.some(dose => isSameDay(dose.whenWasTaken, date)) ?
                css.CalendarDayWithDoses :
                isWithinInterval(date, interval) ?
                  css.CalendarDayWithoutDoses : css.CalendarDayNotInInterval}
            tileDisabled={() => true}
            value={month}
            view={'month'}
            minDetail={'month'}
            showNeighboringMonth={false}
            showNavigation={false}
          />
        </div>
      )}
    </div>
  );
};

export default DosesCalendar;
