import { FormEventHandler, useState } from 'react';
import { auth } from '../services/firebase';
import Loading from './loading';
import css from './login.module.css';

const Login = () => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const onLoginFormSubmit: FormEventHandler = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await auth.signInWithEmailAndPassword(email, password);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <form onSubmit={onLoginFormSubmit} className={css.Login}>

      <input
        onChange={(e) => setEmail(e.target.value)}
        type='email'
        inputMode='email'
        placeholder='E-mail'
        disabled={loading}
      />

      <input
        onChange={(e) => setPassword(e.target.value)}
        type='password'
        placeholder='Senha'
        disabled={loading}
      />

      <input
        type='submit'
        value='Entrar'
        disabled={loading}
        className={css.submit}
      />

      {loading ? <Loading /> : null}

    </form>
  );
};

export default Login;
